import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Templates/Layout";
import Meta from "../utils/Meta";
import Hero from "../components/Templates/Hero";
import Sections from "../components/Sections/Sections";

// Markup
const IndexPage = (props) => {
  const { data, blok } = props;

  let doc, items;
  if (data) {
    doc = JSON.parse(data.index.content);
    items = data.news;
  } else {
    doc = blok;
  }
  const openGraph = {
    type: "website",
    title: doc.title,
    description: doc.body,
  };

  return (
    <Layout>
      <Meta openGraph={openGraph} meta={doc.meta} />
      <React.Fragment>
        <Hero
          title={doc.title && doc.title}
          body={doc.body && doc.body}
          background={doc.hero?.filename}
          video={doc.video && doc.video}
          colour={doc.colour && doc.colour}
        />
        <Sections items={doc?.sections} />
      </React.Fragment>
    </Layout>
  );
};

export default IndexPage;

export const QUERY = graphql`
  query IndexPageQuery {
    index: storyblokEntry(slug: { eq: "home" }) {
      content
    }
    settings: storyblokEntry(slug: { eq: "settings" }) {
      content
    }
  }
`;
