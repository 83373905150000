import sbSettings from "./sbSettings";
import sb404 from "./sb404";
import sbContactIndex from "../../pages/contact/index";
import sbArcadeMachine from "../../templates/arcade_machine";
import sbHomeProduct from "../../templates/home_product";
import sbDefault from "../../templates/page";
import sbIndexPage from "../../pages/index";
import sbGames from "../../templates/games";
import sbGame from "../../templates/game";
import sbManagementSolution from "../../templates/management_solution";
import sbNews from "./sbNews";
import sbNewsPost from "../../templates/news_post";
import sbPrize from "../../templates/prize";
import sbProducts from "../../templates/products";
import sbProductCategories from "../../templates/product_categories";
import sbSupport from "../../templates/support";
import sbCategory from "../../templates/category";
import sbManufacturer from "../../templates/manufacturer";

const ComponentList = {
  settings: sbSettings,
  contact: sbContactIndex,
  default: sbDefault,
  index: sbIndexPage,
  arcade_machine: sbArcadeMachine,
  home_product: sbHomeProduct,
  games: sbGames,
  game: sbGame,
  management_solution: sbManagementSolution,
  news: sbNews,
  news_post: sbNewsPost,
  prize: sbPrize,
  products: sbProducts,
  product_categories: sbProductCategories,
  support: sbSupport,
  category: sbCategory,
  manufacturer: sbManufacturer,
};

const Components = (type) => {
  if (typeof ComponentList[type] === "undefined") {
    return sb404;
  }
  return ComponentList[type];
};

export default Components;
