import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Layout from "../components/Templates/Layout";
import Hero from "../components/Templates/Hero";
import Meta from "../utils/Meta";
import SectionBreadcrumb from "../components/Sections/NewSectionBreadcrumb";
import CardProduct from "../components/Cards/CardProduct";

// ====

const Manufacturer = ({ blok, data, pageContext }) => {
  let { uuid, parent_id } = pageContext || 0;

  let doc;
  let items;
  if (data) {
    doc = JSON.parse(pageContext.story.content);
    items = data.products.nodes;
  } else {
    doc = blok;
    items = null;
  }
  const openGraph = {
    type: "website",
    title: doc.title,
    description: doc.body,
    image: doc.hero,
  };

  return (
    <Layout>
      <Meta openGraph={openGraph} meta={doc.meta} />

      <React.Fragment>
        <Hero
          title={doc.title && doc.title}
          body={doc.body && doc.body}
          logo={doc.logo?.filename}
          background={doc.hero?.filename}
          colour={doc.colour && doc.colour}
          fixed
        />
        <SectionBreadcrumb
          index={0}
          doc={{
            colour: {
              text: "black",
              background: "white",
            },
          }}
        />
        <section className="w-100 brand-background-white brand-text-black">
          <div className="container py-5">
            <div className="row mb-4 gy-4">
              {items?.length > 0 ? (
                items.map((node, index) => {
                  return (
                    <div key={index} className="col-12 col-md-3">
                      <CardProduct
                        data={node}
                        colour={{
                          text: "white",
                          background: "black",
                        }}
                      />
                    </div>
                  );
                })
              ) : (
                <div className="col-12 text-center">
                  <p>No products to display</p>
                </div>
              )}
            </div>
          </div>
        </section>
      </React.Fragment>
    </Layout>
  );
};

export default Manufacturer;

export const QUERY = graphql`
  query ManufacturerQuery($parent_id: Int) {
    products: allStoryblokEntry(
      filter: { is_startpage: { eq: false }, parent_id: { eq: $parent_id } }
    ) {
      nodes {
        full_slug
        field_date_string
        field_title_string
        content
        parent_id
      }
    }
  }
`;

Manufacturer.propTypes = {
  pageContext: PropTypes.object,
  blok: PropTypes.object,
};

Manufacturer.defaultProps = {
  pageContext: null,
  blok: null,
};
