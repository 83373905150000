// export const arcadeMachinesMapper = (inputDataNodes, selectedCategory) => {
//     return inputData?.reduce((nodeArr, node) => {

//         // Parse the content for this node
//         const parsedContent = JSON.parse(node?.content);

//         // Get the category name for this node
//         const categoryName = parsedContent?.category?.story?.name;

//         // If we have a selectedCategory and this node doesnt match it, return.
//         if (selectedCategory && selectedCategory !== categoryName) {
//             return nodeArr;
//         }

//         // See if this products category appears in the categoryList and increase its count if so
//         const category = mappedCategories.find(cat => cat.title === categoryName);
//         if (category) {
//             category.count++;
//         }

//         // Add this node to the list
//         nodeArr.push({
//             full_slug: node?.full_slug,
//             ...parsedContent,
//         });

//         return nodeArr;

//     }, []);
// };

export const standardProductsNodesMapper = nodesList => {
    return nodesList?.map((node) => {

        // Parse the content from this node
        const parsedContent = JSON.parse(node?.content);

        return {
            full_slug: node?.full_slug,
            ...parsedContent
        };
    });
};

export const categoriesMapper = nodesList => {
    return nodesList?.map((node) => {
        return {
            ...JSON.parse(node?.content),
            count: 0
        };
    });
};
