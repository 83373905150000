import React, { useState } from "react";
import { graphql } from "gatsby";
import { useDispatch, useSelector } from "react-redux";

import Layout from "../components/Templates/Layout";
import Meta from "../utils/Meta";
import Hero from "../components/Templates/Hero";

//import SectionBreadcrumbs from "../components/Sections/SectionBreadcrumbs";
import Sections from "../components/Sections/Sections";
import SectionProductList from "../components/Sections/SectionProductList";
import SectionProductCategoriesPanel from "../components/Sections/SectionProductCategoriesPanel";

import {
  categoriesMapper,
  standardProductsNodesMapper,
} from "../helpers/productsMappers";

import { productsActions } from "../store/products";

const ProductCategoriesPage = (props) => {
  const { data, blok, pageContext } = props;

  const dispatch = useDispatch();

  const isSkippingCategories = useSelector(
    (state) => state.products.isSkippingCategories
  );
  const selectedManufacturer = useSelector(
    (state) => state.products.selectedManufacturer
  );

  const [showingCategories, setShowingCategories] = useState(
    (pageContext?.story?.full_slug.includes("arcade-machines") ||
      pageContext?.story?.full_slug.includes("amusement-machines")) &&
      !isSkippingCategories
      ? true
      : false
  );

  const [selectedCategory, setSelectedCategory] = useState("");

  let doc;
  if (pageContext) {
    doc = JSON.parse(pageContext.story.content);
  } else {
    doc = blok;
  }
  const openGraph = {
    type: "website",
    title: doc?.title,
    description: doc?.body,
  };

  const parsedSiteSettings = data?.settings
    ? JSON.parse(data?.settings.content)
    : {};

  const breadCrumbs = pageContext?.story?.full_slug?.split("/").filter(Boolean);

  const selectCategoryHandler = (selectedCategoryParam) => {
    setShowingCategories(false);
    dispatch(productsActions.setIsSkippingCategories(false));
    dispatch(productsActions.setSelectedManufacturer(""));
    setSelectedCategory(selectedCategoryParam);
    breadCrumbs.push(selectedCategoryParam);
  };

  // const deselectCategoryHandler = () => {
  //   breadCrumbs = breadCrumbs.filter((crumb) => crumb !== selectedCategory);
  //   setSelectedCategory("");
  // };

  let mappedCategories = [];
  let mappedProducts = [];

  const arcadeMachinesMapper = () => {
    const mappedArcadeMachines = data?.arcadeMachines?.nodes?.reduce(
      (nodeArr, node) => {
        const parsedContent = JSON.parse(node?.content);
        const categoryName = parsedContent?.category?.story?.name;
        const fullSlugSplit = node?.full_slug?.split("/");
        const manufacturerName = fullSlugSplit[2];

        // If we have a selected category and it isnt the same as this products, return
        if (selectedCategory && selectedCategory !== categoryName) {
          return nodeArr;
        }

        // If we have a selected manufacturer and it isnt the same as this products, return
        if (selectedManufacturer && selectedManufacturer !== manufacturerName) {
          return nodeArr;
        }

        const category = mappedCategories.find(
          (cat) => cat.title === categoryName
        );
        if (category) {
          category.count++;
        }

        nodeArr.push({
          full_slug: node?.full_slug,
          ...parsedContent,
        });

        return nodeArr;
      },
      []
    );
    dispatch(productsActions.setArcadeMachines(mappedArcadeMachines || []));
    mappedProducts = mappedArcadeMachines;
  };

  if (
    pageContext?.story?.full_slug.includes("products/arcade-machines") ||
    pageContext?.story?.full_slug.includes("products/amusement-machines")
  ) {
    // arcade machine

    // Only do this here as we dont have categories for prizes or management solutions
    mappedCategories = categoriesMapper(data?.categories?.nodes);
    arcadeMachinesMapper();
  } else if (pageContext?.story?.full_slug.includes("products/prizes")) {
    // prize
    const mappedPrizes = standardProductsNodesMapper(data?.prizes?.nodes || []);

    mappedProducts = mappedPrizes;
    dispatch(productsActions.setPrizes(mappedPrizes));
  } else {
    // management solution
    const mappedManagementSolutions = standardProductsNodesMapper(
      data?.managementSolutions?.nodes || []
    );

    dispatch(productsActions.setManagementSolutions(mappedManagementSolutions));
    mappedProducts = mappedManagementSolutions;
  }

  return (
    <Layout>
      <Meta openGraph={openGraph} meta={doc.meta} />

      <React.Fragment>
        <Hero title={doc.title} background={doc.image?.filename} />

        {showingCategories && (
          <SectionProductCategoriesPanel
            background="purple"
            categories={mappedCategories}
            clickCategoryHandler={selectCategoryHandler}
            title={selectedCategory ? selectedCategory : doc?.title}
          />
        )}

        {!showingCategories && (
          <SectionProductList
            background="purple"
            products={mappedProducts}
            title={selectedCategory ? selectedCategory : doc?.title}
          />
        )}
        <Sections items={doc?.sections} settings={parsedSiteSettings} />
      </React.Fragment>
    </Layout>
  );
};

export default ProductCategoriesPage;

export const QUERY = graphql`
  query ArcadeMachineQuery {
    categories: allStoryblokEntry(
      filter: { field_component: { in: ["machine_category"] } }
    ) {
      nodes {
        full_slug
        field_date_string
        content
      }
    }
    arcadeMachines: allStoryblokEntry(
      filter: { field_component: { in: ["arcade_machine"] } }
    ) {
      nodes {
        full_slug
        content
      }
    }
    prizes: allStoryblokEntry(filter: { field_component: { in: ["prize"] } }) {
      nodes {
        full_slug
        content
      }
    }
    managementSolutions: allStoryblokEntry(
      filter: { field_component: { in: ["management_solution"] } }
    ) {
      nodes {
        full_slug
        content
      }
    }
    settings: storyblokEntry(
      slug: { eq: "settings" }
      lang: { eq: "default" }
    ) {
      content
    }
  }
`;
