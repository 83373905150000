import React from "react";
import PropTypes from "prop-types";
import Layout from "../components/Templates/Layout";
import Meta from "../utils/Meta";
import SmartText from "../utils/TextHandler";
import Link from "../components/Global/Link";

// import * as styles from "../scss/templates/games.module.scss";

// ====

const Games = (props) => {
  const { pageContext, blok } = props;
  let doc;
  if (pageContext) {
    doc = JSON.parse(pageContext.story.content);
  } else {
    doc = blok;
  }
  const openGraph = {
    type: "website",
    title: doc.title,
    description: doc.body,
    image: doc.image,
  };

  return (
    <Layout>
      <Meta openGraph={openGraph} meta={doc.meta} />
      <section>
        <div className="container">
          <div className="alert alert-info" role="alert">
            This is a <i>games</i> template generated by Storyblok. Edit it at{" "}
            <code>src/template/games.js</code>
          </div>
          {doc.title && <h1 className="brand-font">{doc.title}</h1>}
          {doc.body && <SmartText>{doc.body}</SmartText>}
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row gy-4 row-cols-1 row-cols-md-2 row-cols-lg-3">
            {doc.items.map((node, index) => {
              const game = node.link;

              return (
                <div className="col-6 col-md-3" key={index}>
                  {game && (
                    <>
                      {game.content.title && <h3>{game.content.title}</h3>}
                      {game.content.preview && (
                        <SmartText>{game.content.preview}</SmartText>
                      )}
                      {game.full_slug && <Link to={game.full_slug}>Link</Link>}
                    </>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <section>
        <div className="container py-5 small">
          <h3>Debug: data pulled from Storyblock:</h3>
          <code>{JSON.stringify(doc)}</code>
        </div>
      </section>
    </Layout>
  );
};

export default Games;

Games.propTypes = {
  pageContext: PropTypes.object,
  blok: PropTypes.object,
};

Games.defaultProps = {
  pageContext: null,
  blok: null,
};
