import React from "react";
import { graphql } from "gatsby";
import { useDispatch } from "react-redux";

import Layout from "../components/Templates/Layout";
import Meta from "../utils/Meta";
import Hero from "../components/Templates/Hero";
import Sections from "../components/Sections/Sections";
import SectionProductTypesPanel from "../components/Sections/SectionProductTypesPanel";

import { productsActions } from "../store/products";
import SectionSupportDropdownPanel from "../components/Sections/SectionSupportDropdownPanel";
//import SectionBreadcrumbs from "../components/Sections/SectionBreadcrumbs";

const ProductsPage = (props) => {
  const { data, blok, pageContext } = props;

  const dispatch = useDispatch();

  let doc;
  if (data) {
    doc = JSON.parse(data?.products?.content);
  } else {
    doc = blok;
  }
  const openGraph = {
    type: "website",
    title: doc.title,
    description: doc.body,
  };

  const parsedSiteSettings = data?.settings
    ? JSON.parse(data?.settings.content)
    : {};

  const breadCrumbs = pageContext?.story?.full_slug?.split("/").filter(Boolean);

  const manufacturerList = [];

  const mappedArcadeMachines = data?.arcadeMachines?.nodes?.reduce(
    (nodeArr, node) => {
      const parsedContent = JSON.parse(node?.content);

      // Extract manufacturer name
      const slugSplit = node?.full_slug?.split("/");
      const manufacturerName = slugSplit.length > 3 ? slugSplit[2] : "";

      // Add to manufacturer list if it is not already in there
      if (manufacturerList.indexOf(manufacturerName) === -1) {
        manufacturerList.push(manufacturerName);
      }

      nodeArr.push({
        full_slug: node?.full_slug,
        ...parsedContent,
      });

      return nodeArr;
    },
    []
  );

  dispatch(productsActions.setArcadeMachines(mappedArcadeMachines));
  dispatch(productsActions.setManufacturerList(manufacturerList));

  return (
    <Layout>
      <Meta openGraph={openGraph} meta={doc.meta} />

      <React.Fragment>
        <Hero title={doc.title} background={doc.image?.filename} />

        <SectionProductTypesPanel
          background="purple"
          productTypes={doc?.product_types}
        />

        <SectionSupportDropdownPanel />

        <Sections
          items={doc?.sections}
          settings={parsedSiteSettings}
          notfirst
        />
      </React.Fragment>
    </Layout>
  );
};

export default ProductsPage;

export const QUERY = graphql`
  query ProductsPageQuery {
    products: storyblokEntry(slug: { eq: "products" }) {
      content
    }

    categories: allStoryblokEntry(
      filter: { field_component: { in: ["machine_category"] } }
    ) {
      nodes {
        full_slug
        field_date_string
        content
      }
    }

    arcadeMachines: allStoryblokEntry(
      filter: { field_component: { in: ["arcade_machine"] } }
    ) {
      nodes {
        full_slug
        content
      }
    }

    prizes: allStoryblokEntry(filter: { field_component: { in: ["prize"] } }) {
      nodes {
        full_slug
        content
      }
    }

    managementSolutions: allStoryblokEntry(
      filter: { field_component: { in: ["management_solution"] } }
    ) {
      nodes {
        full_slug
        content
      }
    }
    settings: storyblokEntry(
      slug: { eq: "settings" }
      lang: { eq: "default" }
    ) {
      content
    }
  }
`;
