import React from "react";
import PropTypes from "prop-types";
import Link from "../Global/Link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { method } from "lodash";

const Options = (props) => {
  const { availability, contact, blue_alligator, method } = props;

  if (!availability) return null;

  return (
    <div className="mt-5 pt-5 border-top">
      {method === "multiple" ? (
        <>
          <h4 className="brand-font text-uppercase">Options</h4>
          <ul className="list-unstyled mb-4">
            {availability &&
              availability.map((node, index) => {
                if (node === "buy") {
                  return (
                    <li key={index}>
                      <FontAwesomeIcon icon="check" /> Available to purchase
                    </li>
                  );
                }
                if (node === "rent") {
                  return (
                    <li key={index}>
                      <FontAwesomeIcon icon="check" /> Available to lease
                    </li>
                  );
                }
              })}
          </ul>
        </>
      ) : availability === "buy" ? (
        <p>
          <FontAwesomeIcon icon="check" /> Available to purchase
        </p>
      ) : availability === "soon" ? (
        <p>This product is coming soon</p>
      ) : (
        <p>
          <FontAwesomeIcon icon="xmark" /> Unavailable to purchase
        </p>
      )}

      {(contact || blue_alligator.url) && (
        <div className="d-flex justify-content-center justify-content-lg-start">
          {contact && (
            <Link button to={contact} icon="envelope" className="me-4">
              Email
            </Link>
          )}

          {blue_alligator.url && (
            <Link button to={blue_alligator} icon="cart-plus">
              Blue Alligator
            </Link>
          )}
        </div>
      )}
    </div>
  );
};

export default Options;

Options.propTypes = {
  availability: PropTypes.oneOf,
  contact: PropTypes.array,
  blue_alligator: PropTypes.array,
  method: PropTypes.string,
};

Options.defaultProps = {
  availability: null,
  contact: [],
  blue_alligator: [],
  method: "multiple",
};
