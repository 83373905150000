import React from "react";
import PropTypes from "prop-types";
import Layout from "../components/Templates/Layout";
import Meta from "../utils/Meta";
import SmartText from "../utils/TextHandler";
import Image from "../components/Global/Image";

// import * as styles from "../scss/templates/game.module.scss";

// ====

const Game = (props) => {
  const { pageContext, blok } = props;
  let doc;
  if (pageContext) {
    doc = JSON.parse(pageContext.story.content);
  } else {
    doc = blok;
  }
  const openGraph = {
    type: "website",
    title: doc.title,
    description: doc.body,
    image: doc.image,
  };

  return (
    <Layout>
      <Meta openGraph={openGraph} meta={doc.meta} />
      <section>
        <div className="container">
          <div className="alert alert-info" role="alert">
            This is a <i>game</i> template generated by Storyblok. Edit it at{" "}
            <code>src/template/game.js</code>
          </div>
          {doc.keyart && <Image src={doc.keyart.filename} />}
          {doc.title && <h1 className="brand-font">{doc.title}</h1>}
          {doc.body && <SmartText>{doc.body}</SmartText>}
        </div>
      </section>
      <section>
        <div className="container py-5 small">
          <h3>Debug: data pulled from Storyblock:</h3>
          <code>{JSON.stringify(doc)}</code>
        </div>
      </section>
    </Layout>
  );
};

export default Game;

Game.propTypes = {
  pageContext: PropTypes.object,
  blok: PropTypes.object,
};

Game.defaultProps = {
  pageContext: null,
  blok: null,
};
