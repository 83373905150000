import React from "react";
import { useDispatch } from "react-redux";
import { graphql, navigate } from "gatsby";

import Layout from "../../components/Templates/Layout";
import Meta from "../../utils/Meta";
import Hero from "../../components/Templates/Hero";

import { contactActions } from "../../store/contact";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "../../components/Global/Link";

// Markup
const ContactIndexPage = (props) => {
  const { data, blok } = props;

  const dispatch = useDispatch();

  let doc;
  if (data) {
    doc = JSON.parse(data?.contact?.content);
  } else {
    doc = blok;
  }
  const openGraph = {
    type: "website",
    title: doc.title,
    description: doc.body,
  };

  const existingCustomerHandler = () => {
    dispatch(contactActions.setIsExistingCustomer(true));
    navigate("/contact/existing");
  };
  const newCustomerHandler = () => {
    dispatch(contactActions.setIsExistingCustomer(false));
    navigate("/contact/new");
  };

  return (
    <Layout>
      <Meta openGraph={openGraph} meta={doc.meta} />

      <React.Fragment>
        <Hero
          title={doc.title && doc.title}
          background={doc.image?.filename}
          colour={doc.colour && doc.colour}
        />
        <section className="w-100 py-5 brand-background-white brand-text-black brand-background-clip border">
          <div className="container py-5">
            <div className="row mb-5">
              <div className="col-12">
                <h1 className="brand-font text-uppercase text-center brand-text-purple">
                  Let's get started...
                </h1>
              </div>
            </div>

            <div className="row justify-content-center gy-4">
              <div className="col col-12 col-md-6 col-lg-4">
                <div className="d-flex flex-column brand-border-pink brand-border-radius overflow-hidden h-100 py-5">
                  <div className="text-center mb-5">
                    <FontAwesomeIcon icon="check" className="fs-1" />
                  </div>
                  <div className="d-flex flex-column h-100 px-4 text-center">
                    <h5 className="mb-5">
                      I already own or lease a{" "}
                      <span className="text-nowrap">Bandai Namco</span>{" "}
                      amusement machine
                    </h5>
                    <div className="mt-auto">
                      <Link
                        button
                        colour="pink"
                        icon
                        onClick={existingCustomerHandler}
                      >
                        Next
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col col-12 col-md-6 col-lg-4">
                <div className="d-flex flex-column brand-border-pink brand-border-radius overflow-hidden h-100 py-5">
                  <div className="text-center mb-5">
                    <FontAwesomeIcon icon="basket-shopping" className="fs-1" />
                  </div>
                  <div className="d-flex flex-column h-100 px-4 text-center">
                    <h5 className="mb-5">
                      I'm interested in buying or leasing a{" "}
                      <span className="text-nowrap">Bandai Namco</span> product
                    </h5>
                    <div className="mt-auto">
                      <Link
                        button
                        colour="pink"
                        icon
                        onClick={newCustomerHandler}
                      >
                        Next
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    </Layout>
  );
};

export default ContactIndexPage;

export const QUERY = graphql`
  query ContactIndexPageQuery {
    contact: storyblokEntry(slug: { eq: "contact" }) {
      content
    }
    settings: storyblokEntry(slug: { eq: "settings" }) {
      content
    }
  }
`;
