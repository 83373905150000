import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Layout from "../components/Templates/Layout";
import Hero from "../components/Templates/Hero";
import Meta from "../utils/Meta";
import Sections from "../components/Sections/Sections";

// ====

const Page = (props) => {
  const { data, pageContext, blok } = props;
  let doc;
  if (pageContext) {
    doc = JSON.parse(pageContext.story.content);
  } else {
    doc = blok;
  }
  const openGraph = {
    type: "website",
    title: doc.title,
    description: doc.body,
    image: doc.hero,
  };

  const parsedSiteSettings = data?.settings
    ? JSON.parse(data?.settings.content)
    : {};

  return (
    <Layout>
      <Meta openGraph={openGraph} meta={doc.meta} />

      <React.Fragment>
        <Hero
          title={doc.title && doc.title}
          body={doc.body && doc.body}
          logo={doc.logo?.filename}
          background={doc.hero?.filename}
          colour={doc.colour && doc.colour}
          fixed
        />
        <Sections
          items={doc.sections && doc.sections}
          settings={parsedSiteSettings}
        />
      </React.Fragment>
    </Layout>
  );
};

export default Page;

export const QUERY = graphql`
  query DefaultPageQuery {
    settings: storyblokEntry(slug: { eq: "settings" }) {
      content
    }
  }
`;

Page.propTypes = {
  pageContext: PropTypes.object,
  blok: PropTypes.object,
};

Page.defaultProps = {
  pageContext: null,
  blok: null,
};
