import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Hero from "../components/Templates/Hero";
import Image from "../components/Global/Image";
import Video from "../components/Global/Video";
import Link from "../components/Global/Link";
import SmartText from "../utils/TextHandler";
import Layout from "../components/Templates/Layout";
import Options from "../components/Templates/Options";
import Meta from "../utils/Meta";
import SectionBreadcrumb from "../components/Sections/NewSectionBreadcrumb";

const HomeProduct = (props) => {
  const { pageContext, blok } = props;

  let doc;
  if (pageContext) {
    doc = JSON.parse(pageContext.story.content);
  } else {
    doc = blok;
  }
  const openGraph = {
    type: "website",
    title: doc.title,
    description: doc.body,
    image: doc.hero,
  };

  return (
    <Layout>
      <Meta openGraph={openGraph} meta={doc.meta} />

      <React.Fragment>
        <Hero
          title={doc.hero_title || doc.title}
          body={doc.body && doc.body}
          logo={doc.logo?.filename}
          background={doc.hero?.filename}
          colour={doc.colour && doc.colour}
          fixed
        />
        <SectionBreadcrumb
          index={0}
          doc={{
            colour: {
              text: "black",
              background: "white",
            },
          }}
        />
        <section className="w-100 brand-background-white brand-text-black">
          <div className="container py-5">
            <div className="row justify-content-between">
              <div className="order-1 order-lg-0 col-12 col-lg-5">
                {doc?.title && <h2 className="brand-font">{doc.title}</h2>}
                <p className="mb-5"></p>

                {doc?.description && <SmartText>{doc?.description}</SmartText>}
                {doc?.video && typeof doc?.video !== "object" && (
                  <>
                    <Video
                      video={doc.video}
                      containerClassName="ratio ratio-16x9 mt-5"
                    />
                  </>
                )}
                {doc?.features && (
                  <div className="mt-5 pt-5 border-top">
                    <h4 className="brand-font text-uppercase">Features</h4>
                    <SmartText>{doc?.features}</SmartText>
                  </div>
                )}
                {doc?.specs && (
                  <div className="mt-5 pt-5 border-top">
                    <h4 className="brand-font text-uppercase">Specification</h4>
                    <SmartText>{doc?.specs}</SmartText>
                  </div>
                )}
                {doc.availability?.length > 0 && (
                  <Options
                    availability={doc.availability}
                    contact="home@bandainamco.co.uk"
                    method="single"
                  />
                )}
              </div>
              <div className="order-0 order-lg-1 col-12 col-lg-6">
                <div className="brand-background-grad brand-border-radius overflow-hidden mb-5 mb-lg-0">
                  <Image
                    src={doc?.product?.filename}
                    sm="600x0"
                    md="600x0"
                    lg="800x0"
                    alt={doc?.product?.alt}
                    className="w-100"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {(doc.parts || doc.manual?.filename) && (
          <section className="brand-background-pink brand-text-white">
            <div className="container py-5">
              <div className="row justify-content-between">
                {doc.parts?.length > 0 && (
                  <div className="col-12 col-lg-auto order-1 order-lg-0">
                    <h2 className="brand-font text-uppercase mb-5 text-center text-lg-start">
                      Parts
                    </h2>
                  </div>
                )}
                {doc.manual?.filename && (
                  <div className="col-12 ms-auto order-0 mb-5 text-center col-lg-auto order-lg-1 mb-lg-4">
                    <Link to={doc.manual} icon="file-pdf" button colour="white">
                      Download Manual
                    </Link>
                  </div>
                )}
              </div>
              <div className="row gy-4">
                {doc.parts &&
                  doc.parts.map((node, index) => {
                    return (
                      <div
                        className="col-6 col-md-2 col-lg-1 text-center"
                        key={index}
                      >
                        <Image
                          src={node.image?.filename}
                          sm="100x100"
                          md="100x100"
                          lg="100x100"
                          alt={node.image?.alt}
                          className="brand-border-radius mb-1"
                        />
                        {node.name && <p className="small m-0">{node.name}</p>}
                        {node.number && (
                          <p className="small m-0">
                            <strong>{node.number}</strong>
                          </p>
                        )}
                      </div>
                    );
                  })}
              </div>
            </div>
          </section>
        )}
      </React.Fragment>
    </Layout>
  );
};

export default HomeProduct;

export const QUERY = graphql`
  query HomeProductPageQuery {
    settings: storyblokEntry(slug: { eq: "settings" }) {
      content
    }
  }
`;

HomeProduct.propTypes = {
  pageContext: PropTypes.object,
  blok: PropTypes.object,
};

HomeProduct.defaultProps = {
  pageContext: null,
  blok: null,
};
