import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Layout from "../components/Templates/Layout";
import Meta from "../utils/Meta";
import Sections from "../components/Sections/Sections";
import Hero from "../components/Templates/Hero";
import { useDispatch } from "react-redux";

import { productsActions } from "../store/products";
import SectionSupportDropdownPanel from "../components/Sections/SectionSupportDropdownPanel";

const Support = (props) => {
  const { data, pageContext, blok } = props;

  const dispatch = useDispatch();

  let doc;
  if (pageContext) {
    doc = JSON.parse(pageContext.story.content);
  } else {
    doc = blok;
  }
  const openGraph = {
    type: "website",
    title: doc?.title,
    description: doc?.body,
    image: doc?.image,
  };

  const manufacturerList = [];

  const mappedArcadeMachines = data?.arcadeMachines?.nodes?.reduce(
    (nodeArr, node) => {
      const parsedContent = JSON.parse(node?.content);

      // Extract manufacturer name
      const slugSplit = node?.full_slug?.split("/");
      const manufacturerName = slugSplit.length > 3 ? slugSplit[2] : "";

      // Add to manufacturer list if it is not already in there
      if (manufacturerList.indexOf(manufacturerName) === -1) {
        manufacturerList.push(manufacturerName);
      }

      nodeArr.push({
        full_slug: node?.full_slug,
        ...parsedContent,
      });

      return nodeArr;
    },
    []
  );

  dispatch(productsActions.setArcadeMachines(mappedArcadeMachines));
  dispatch(productsActions.setManufacturerList(manufacturerList));

  const parsedSiteSettings = data?.settings
    ? JSON.parse(data?.settings.content)
    : {};

  return (
    <Layout>
      <Meta openGraph={openGraph} meta={doc.meta} />

      <React.Fragment>
        <Hero title={doc.title} background={doc.image?.filename} />

        <SectionSupportDropdownPanel className="brand-background-clip" />

        <Sections
          items={doc.sections && doc.sections}
          settings={parsedSiteSettings}
          notfirst
        />

      </React.Fragment>

    </Layout>
  );
};

export default Support;

export const QUERY = graphql`
  query SupportPageQuery {
    arcadeMachines: allStoryblokEntry(
      filter: { field_component: { in: ["arcade_machine"] } }
    ) {
      nodes {
        full_slug
        content
      }
    }

    settings: storyblokEntry(slug: { eq: "settings" }) {
      content
    }
  }
`;

Support.propTypes = {
  pageContext: PropTypes.object,
  blok: PropTypes.object,
};

Support.defaultProps = {
  pageContext: null,
  blok: null,
};
